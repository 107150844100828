
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader';
import { observable, action, makeObservable, computed, makeAutoObservable, toJS } from 'mobx';
import informService from 'services/inform.service';


class InformStore {
    items: any = [];
    errors: any = []
    perPage: number = 50
    page: number = 1
    pageCount: number = 1
    totalCount: number = 0
    inform: any = {}
    loadingUpload: boolean = false
    loadingJob: boolean = false
    previewUpload: any = []
    loading: boolean = false;
    informJobs: any = {};

    constructor() {
        // makeAutoObservable(this, {
        //     page: observable,
        //     errors: observable,
        //     totalCount: observable,
        //     pageCount: observable,
        //     items: observable,
        //     informJobs: observable
        // })
        makeAutoObservable(this)

    }
    @computed
    get totalCountItems() {
        return this.totalCount;
    }
    @computed
    get informData() {
        return this.inform;
    }

    @computed
    get loadingInform() {
        return this.loading;
    }


    @computed
    informImages() {
        if (this.inform.images) {
            const data: any[] = this.inform.images.map((item: any) => ({
                url: item.url
            }))
        }
    }


    @action setItems = (items: any) => {
        this.items = toJS(items);
    }

    @action setLoading = (val: boolean) => {
        this.loading = val
    }
    @action setLoadingJob = (val: boolean) => {
        this.loadingJob = val
    }
    @action setInform = (data: any) => {
        this.inform = data
    }
    @action setInformJobs = (data: any) => {
        this.informJobs = data
    }

    @action setTotalCount = (count: number) => {
        this.totalCount = count
    }

    @action setPage = (page: number) => {
        this.page = page ? page : 1;
    }

    @action loadInforms() {
        this.setLoading(true)
        return informService.all({
            page: this.page,
            'per-page': this.perPage,
        }).then((rs: any) => {
            const meta = rs.data._meta;
            this.setItems(rs.data.items)
            // this.totalCount = meta.totalCount;
            // this.page = meta.currentPage;
            this.perPage = meta.perPage;
            this.pageCount = meta.pageCount;
            this.setTotalCount(meta.totalCount)
            this.setPage(meta.currentPage)

            return this.items;
        }).catch(() => {
            this.setLoading(false)
        }).finally(() => this.setLoading(false))
    }
    @action getInformDetail(id: any) {
        this.setLoading(true)
        return informService.get(id).then((rs: any) => {
            this.setInform(rs.data.data)
            return this.inform;
        }).catch(() => {

        }).finally(() => this.setLoading(false))
    }
    @action getInformJobs(id: any) {
        this.setLoadingJob(true)
        return informService.getJobsById(id).then((rs: any) => {
            this.setInformJobs(rs.data.data)
            this.setLoadingJob(false)
            return rs;
        }).catch(() => {
            this.setLoadingJob(false)
        }).finally(() => this.setLoading(false))
    }
    @action upload(id: any, formData: any) {
        this.loadingUpload = true
        return informService.upload(id, formData).then((rs: any) => {
            this.loadingUpload = false
            return rs;
        }).catch((err) => {
            this.loadingUpload = false
            return err;
        });
    }
    @action create(data: any) {
        this.loadingUpload = true
        return informService.create(data).then((rs: any) => {
            if (rs.data) {
                this.getInformDetail(rs.data.data.id);
                this.loadingUpload = false
            }
            return rs;
        }).catch(() => {
            this.loadingUpload = false
        });
    }
}

const informStore = new InformStore();
export default informStore