import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  Space,
  Modal,
  List,
  Picker,
  NumberKeyboard,
  Result,
  Card,
  Toast,
  Dialog,
  NoticeBar,
} from "antd-mobile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../stores";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import ProjectSelectInput from "./ProjectSelectInput";
import { SearchOutline } from "antd-mobile-icons";
import projectService from "../services/project.service";
import authStore from "../store/auth.store";
import Layout from "./Layout";
import {
  IoLogInOutline,
  IoLogInSharp,
  IoReloadCircle,
  IoWarning,
} from "react-icons/io5";
import { type } from "os";
import authService from "services/auth.service";

type FieldType = { account?: string; loginMethod?: "login" | "register" };

const Register = function (props: any) {
  const [form] = Form.useForm<FieldType>();
  const account = Form.useWatch("account", form);
  const loginMethod = Form.useWatch("loginMethod", form);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [successMsg, setSuccessMsg] = useState<string>();
  const [projects, setProjects] = useState<any>([]);
  const [seletctedProject, setSelectedProject] = useState<any>();
  const [selectedUnit, setSelectedUnit] = useState<any>();
  const [loadingUnit, setLoadingUnit] = useState<boolean>(false);
  const [loadingProject, setLoadingProject] = useState<boolean>(false);
  const [unitPicker, setUnitPicker] = useState<any>([]);
  const [visibleNumPad, setVisibleNumPad] = useState<boolean>(false);

  useEffect(() => {
    setLoadingUnit(true);

    projectService
      .all()
      .then((rs: any) => {
        setLoadingUnit(false);
        setProjects(rs.data.data);
      })
      .catch(() => setLoadingUnit(false));
    if (props.type) {
      form.setFieldValue("loginMethod", props.type);
    }
  }, []);

  let navigate = useNavigate();
  const { authStore } = useStore();

  const register = (form: any) => {
    setErrorMsg("");
    setSuccessMsg("");
    authService
      .check(form.username)
      .then(() => {
        authStore
          .register(form)
          .then((rs: any) => {
            resetForm();

            Dialog.alert({
              content: rs.data.message,
              confirmText: "OK",
            });
            setTimeout(() => {
              navigate("/login");
            }, 2000);
            // window.location.replace("/login");
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.response.status > 400) {
          const data = err.response.data;
          Dialog.alert({
            header: "Error",
            content: data.message,
            confirmText: "OK",
          });
        }
      });
  };

  const login = (form: any) => {
    setLoading(true);
    setErrorMsg("");
    setSuccessMsg("");
    authStore.setToken("");
    authStore
      .login(form)
      .then((rs: any) => {
        console.log(rs);
        setLoading(false);
        if (rs.status) {
          Toast.show(rs.data.data.message);
          setSuccessMsg(rs.data.data.message);
          if (authStore.isLoggedIn()) {
            resetForm();
            window.location.replace("/main");
          }
        }
      })
      .catch((err: any) => {
        setLoading(false);
        if (err.response) {
          if (err.response.status === 500) {
            setErrorMsg(err.message);
            Modal.alert({
              header: (
                <>
                  <IoWarning />
                </>
              ),
              content: <>{err.response.statusText}</>,
              confirmText: "close",
            });
          } else {
            setErrorMsg(err.response.data.message);
          }
        }
      });
  };

  const onSelectProject = (item: any) => {
    setSelectedProject(item);
    getHomes(item.id);
    form.setFieldValue("project_id", item.id);
  };

  const getHomes = (projectId: any) => {
    setLoadingUnit(true);
    projectService
      .getHomesByProjectId(projectId)
      .then((rs: any) => {
        setLoadingUnit(false);
        if (rs.data.data) {
          const listUnit = rs.data.data;
          const homes = listUnit.map((item: any) => {
            const type = item.homeType ? item.homeType.name + "  " : "";
            return {
              label: `${type}${item.home_no ? item.home_no : "ยังไม่มีเลขที่"}${
                item.plan_no ? "  แปลง (" + item.plan_no + ")" : ""
              }`,
              value: item.id,
            };
          });
          setUnitPicker(homes);
        }
      })
      .catch(() => setLoadingUnit(false));
  };

  const selectProject = () => {
    console.log('select a project');
    setSelectedUnit([]);
    form.setFieldValue("home_id", "");
    Modal.alert({
      header: "โครงการ",
      confirmText: "ตกลง",
      content: (
        <ProjectSelectInput
          selected={0}
          items={projects}
          onChange={(data: any) => {
            onSelectProject(data);
            Modal.clear();
          }}
        />
      ),
    });
  };

  const resetForm = () => {
    const loginMethod = form.getFieldValue("loginMethod");
    form.resetFields();
    setSelectedProject({});
    setSelectedUnit([]);
    form.setFieldValue("loginMethod", loginMethod);
    setErrorMsg("");
    setSuccessMsg("");
  };

  const onInputNumber = (value: any) => {
    let mobile = form.getFieldValue("username") || "";
    let text = "";
    if (value) {
      if (mobile) {
        mobile = mobile.toString();
        text = `${mobile}${value}`;
      } else {
        text = value;
      }
      if (text && text.length <= 10) {
        form.setFieldValue("username", text);
      }
    }
  };
  const onDeleteNumber = () => {
    let mobile = form.getFieldValue("username");
    if (mobile) {
      mobile = mobile.toString();
      const text = mobile.substr(0, mobile.length - 1);
      form.setFieldValue("username", text);
    }
  };
  const onClearNumber = () => {
    let mobile = form.getFieldValue("username");
    form.setFieldValue("username", "");
  };

  const onSubmit = (values: any) => {
    // console.log(e);
    //    e.preventDefault();
    //    const values = form.getFieldsValue();
    if (loginMethod === "login") {
      login(values);
    } else if (loginMethod === "register") {
      register(values);
    }
    return false;
  };
  return (
    <>
      <Card
        style={{
          maxWidth: "500px",
          width: "100%",
        }}
      >
        <Form
          form={form}
          layout="horizontal"
          initialValues={{ loginMethod: "login" }}
          onFinish={onSubmit}
          footer={
            <>
              <div
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "var(--adm-color-weak)",
                }}
              >
                <Button
                  type="submit"
                  loading={loading}
                  color="primary"
                  size="large"
                  shape="default"
                  onClick={(e) => {
                    // e.preventDefault()
                    // form.submit();
                  }}
                  style={
                    {
                      // backgroundColor: "green"
                    }
                  }
                >
                  <IoLogInSharp />
                  <Space />
                  {loginMethod === "login" ? "เข้าสู่ระบบ" : "ลงทะเบียนใหม่"}
                </Button>

                <Button
                  type="button"
                  onClick={() => resetForm()}
                  size="large"
                  color="warning"
                  shape="default"
                  style={{
                    margin: "0px 5px",
                  }}
                >
                  <IoReloadCircle />
                  Reset
                </Button>
                {/* <Button
              type="button"
              onClick={() => navigate("/main")}
              size="small"
            >
              กลับ
            </Button> */}
              </div>
            </>
          }
        >
          {/*      <Form.Header>ลงทะเบียน</Form.Header>*/}
          <Form.Item name="loginMethod">
            <Radio.Group>
              <Space>
                <Radio value="login">เข้าสู่ระบบ</Radio>
                <Radio value="register">ลงทะเบียนใหม่</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <>
            {loginMethod === "login" && (
              <>
                <Form.Item
                  name="username"
                  label="เบอร์มือถือ"
                  rules={[
                    {
                      required: true,
                      message: "เบอร์มือถือ",
                    },
                  ]}
                >
                  <Input
                    placeholder="เบอร์มือถือ"
                    autoComplete="off"
                    type="tel"
                    onKeyDown={() => {
                      return false;
                    }}
                    onClick={() => {
                      return false;
                    }}
                    clearable
                  />
                </Form.Item>
              </>
            )}
            {loginMethod === "register" && (
              <>
                <Form.Item
                  name="project_id"
                  label="โครงการ"
                  rules={[
                    {
                      required: true,
                      message: "โปรดเลือก โครงการ",
                    },
                  ]}
                >
                  <Button
                    type="button"
                    onClick={selectProject}
                    loading={loadingProject}
                    size="mini"
                    style={{
                      backgroundColor: "#222222",
                      color: "#ffffff",
                    }}
                  >
                    <SearchOutline /> เลือกโครงการ
                  </Button>
                  <p> {seletctedProject ? seletctedProject.name : ""}</p>
                </Form.Item>

                <Form.Item
                  label="บ้านเลขที่"
                  name="home_id"
                  rules={[
                    {
                      required: true,
                      message: "โปรดเลือก บ้าน/ห้อง",
                    },
                  ]}
                >
                  <Picker
                    loading={loadingUnit}
                    columns={[unitPicker]}
                    onConfirm={(v, item) => {
                      setSelectedUnit(item.items);
                      if (v) {
                        form.setFieldValue("home_id", v[0]);
                      }
                    }}
                    onSelect={(v, item) => {
                      console.log(" select", v[0], item.items);
                    }}
                    onCancel={() => {
                      setSelectedUnit({});
                    }}
                    cancelText="ปิด"
                    confirmText="ตกลง"
                  >
                    {(_, actions) => (
                      <>
                        {unitPicker.length > 0 ? (
                          <Button
                            size="mini"
                            type="button"
                            onClick={actions.open}
                            style={{
                              backgroundColor: "#222222",
                              color: "#ffffff",
                            }}
                          >
                            <SearchOutline /> เลือกบ้าน
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Picker>
                  <p>
                    {selectedUnit !== undefined && selectedUnit[0]
                      ? selectedUnit[0].label
                      : ""}
                  </p>
                </Form.Item>
                <Form.Item
                  name="username"
                  label="เบอร์มือถือ"
                  rules={[
                    {
                      required: true,
                      message: "โปรดระบุเบอร์มือถือ",
                    },
                  ]}
                >
                  <Input
                    placeholder="เบอร์มือถือ"
                    autoComplete="off"
                    type="tel"
                    onClick={() => {
                      return false;
                    }}
                    onKeyDown={() => {
                      return false;
                    }}
                    clearable
                  />
                </Form.Item>
                <Form.Item
                  name="name"
                  label="ชื่อ-สกุล"
                  rules={[
                    {
                      required: true,
                      message: "โปรดระบุ ชื่อ-สกุล",
                    },
                  ]}
                >
                  <Input placeholder="ชื่อ-สกุล" autoComplete="off" />
                </Form.Item>
              </>
            )}
          </>
        </Form>
        {successMsg ? <NoticeBar color="info" content={successMsg} /> : null}
        {errorMsg ? <NoticeBar color="error" content={errorMsg} /> : null}
      </Card>

      <NumberKeyboard
        visible={visibleNumPad}
        onInput={onInputNumber}
        onClose={() => setVisibleNumPad(false)}
        // onConfirm={() => {}}
        onDelete={() => onDeleteNumber()}
        // customKey={'-'}
        confirmText="ตกลง"
      />
    </>
  );
};

export default observer(Register);
