
import axios from "axios";
// const API_ROOT = "http://localhost:88/api/homecare";
// const API_ROOT = "http://192.168.11.2/api/homecare";
const API_ROOT = "https://otower588.com/api/homecare";

// const encode = encodeURIComponent;
const handleErrors = (err: any) => {
  if (err && err.response && err.response.status === 401) {
    console.log("logout");
    localStorage.removeItem("token");
    window.location.replace("/login");
    //new authStore().logout();
    //stores.authStore.logout()
  }
  return err;
};


let token: any = "";
token = localStorage.getItem('token')

axios.defaults.baseURL = API_ROOT;

axios.interceptors.request.use(
  (config: any) => {
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error:any) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (config: any) => {
    return config;
  },
  (error:any) => {
    handleErrors(error)
    return Promise.reject(error);
  }
);


export const http = axios









