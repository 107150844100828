import React, { useEffect, useState } from "react";
import {
  List,
  DotLoading,
  Card,
  PullToRefresh,
  Space,
  Image,
  Button,
  Popup,
  Grid,
  Tabs,
  Empty,
  SpinLoading,
  Ellipsis,
  Dialog,
} from "antd-mobile";
import { useNavigate } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "stores";
import InfiniteScroll from "react-infinite-scroll-component";
import { toJS } from "mobx";
import { sleep } from "antd-mobile/es/utils/sleep";
import { IoPersonCircle, IoPulseOutline, IoWifiOutline } from "react-icons/io5";
import InformDetail from "./InformDetail";
import {
  CloseCircleFill,
  EditFill,
  EditSFill,
  UserCircleOutline,
} from "antd-mobile-icons";
import dayjs from "dayjs";
import {
  GoArrowRight,
  GoBookmark,
  GoCalendar,
  GoChevronRight,
  GoPlus,
  GoSearch,
} from "react-icons/go";
import authStore from "store/auth.store";

const InformsInfiniteScroll = () => {
  const [items, setItems] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [visiblePopupDetail, setVisiblePopup] = useState(false);
  const [currentViewTab, setCurrentViewTab] = useState<string>("detail");
  const [currentViewCountJob, setCurrentViewCountJob] = useState(0);
  const { informStore } = useStore();
  const navigate = useNavigate();

  async function fetchData() {
    informStore
      .loadInforms()
      .then((rs: any) => {
        if (rs) {
          const items: any = informStore.items;
          setItems(items);
          if (items.length === informStore.totalCountItems) {
            setHasMore(false);
          }
        }
      })
      .catch(() => {
        informStore.setLoading(false);
        setHasMore(false);
      })
      .finally(() => {
        informStore.setLoading(false);
      });
  }
  async function loadMore() {
    informStore.loadInforms().then((rs: any) => {
      if (rs) {
        const append: any = informStore.items;
        if (informStore.page < informStore.pageCount) {
          let newItems: any = [...items, ...append];
          setItems(newItems);
        }
      }
    });
  }

  useEffect(() => {
    initLoadData();
  }, []);

  const initLoadData = () => {
    informStore.setPage(1);
    informStore.setTotalCount(0);
    fetchData().finally(() => {});
  };

  const fetchMoreData = () => {
    if (items.length === informStore.totalCountItems) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      informStore.setPage(informStore.page + 1);
      loadMore();
    }, 500);
  };

  const viewInform = (item: any) => {
    setCurrentViewTab("detail");
    setVisiblePopup(true);
    informStore.setLoading(true);
    informStore.setInformJobs([]);
    informStore.setInform({});
    informStore.getInformDetail(item.id).then(() => {
      setTimeout(() => {
        if (informStore.informData && informStore.informData.work_status) {
          if (Number(informStore.informData.work_status) === 6) {
            Dialog.alert({
              header: "โปรดทราบ",
              content:
                "กรุณา ตรวจข้อมูล/แก้ไข พร้อมกับอัพโหลดรูปภาพ  และ กดยืนยันส่งใบแจ้งซ่อมเลขที่  " +
                informStore.informData.code,
              confirmText: "ปิด",
            });
          }
        }
      }, 3000);
    });
  };

  return (
    <>
      <div style={{}}>
        <PullToRefresh
          refreshingText="กำลังโหลดข้อมูล"
          pullingText="กดดึงเพื่อโหลดข้อมูล"
          completeText="เรียบร้อย"
          onRefresh={async () => {
            await sleep(1000);
            await initLoadData();
          }}
        >
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<SpinLoading />}
            endMessage={
              <div style={{ textAlign: "center", marginTop: 10 }}>
                <Button
                  type="button"
                  size="middle"
                  onClick={() => navigate("/main/informs/create")}
                >
                  <GoPlus />
                  แจ้งซ่อม
                </Button>
              </div>
            }
          >
            {items && items.length > 0 ? (
              <>
                <List>
                  {items.map((i: any, index: number) => (
                    <List.Item
                      clickable={false}
                      key={"item" + index}
                      style={{
                        backgroundColor:
                          Number(i.work_status) === 6 ? "#ffefdf" : "",
                      }}
                      extra={<></>}
                      arrow={
                        <div
                          onClick={() => viewInform(i)}
                          style={{ cursor: "pointer" }}
                        >
                          <GoChevronRight />
                        </div>
                      }
                      prefix={
                        i.cover ? (
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => viewInform(i)}
                          >
                            <div
                              key={"cover" + index}
                              style={{
                                fontSize: 11,
                                fontWeight: "bold",
                                textAlign: "center",
                                // backgroundColor:
                                //   i.work_status === 1 ? "#beffb3" : "#c3c3c3",
                                padding: 3,
                              }}
                              className=""
                            >
                              {i.code}
                            </div>
                            <Image
                              fit="contain"
                              key={"img" + index}
                              src={i.cover.thumbnail}
                              width={80}
                            />
                          </div>
                        ) : (
                          <>
                            <div
                              key={"cover" + index}
                              style={{
                                fontSize: 11,
                                fontWeight: "bold",
                                textAlign: "center",
                                width: "80px",
                                backgroundColor:
                                  i.work_status === 1 ? "" : "#c3c3c3",
                                padding: 3,
                                cursor: "pointer",
                              }}
                              className=""
                            >
                              {i.code}
                            </div>
                          </>
                        )
                      }
                      title={i.date_inform_string}
                      description={
                        <>
                          <Grid columns={1}>
                            {i.informer ? (
                              <Grid.Item>
                                <UserCircleOutline />{" "}
                                {i.informer ? i.informer : ""}
                              </Grid.Item>
                            ) : (
                              ""
                            )}
                            <Grid.Item>
                              {Number(i.work_status) === 6 ? (
                                <>
                                  <Button
                                    type="button"
                                    size="small"
                                    color="warning"
                                    onClick={() => {
                                      navigate("/main/informs/" + i.id);
                                    }}
                                  >
                                    <EditSFill />
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  type="button"
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    console.log(i);
                                    viewInform(i);
                                  }}
                                  style={
                                    {
                                      // padding: 2,
                                      // borderRadius: 0,
                                    }
                                  }
                                >
                                  <GoSearch />
                                </Button>
                              )}

                              <span
                                key={i.id}
                                style={{
                                  // border: `solid 1px ${
                                  //   i.workStatusColor[i.work_status]
                                  // }`,
                                  color: `${i.workStatusColor[i.work_status]}`,
                                  padding: 2,
                                  marginLeft: 3,
                                  float: "right",
                                  marginRight: 5,
                                  borderRadius: 5,
                                  minWidth: 120,
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                              >
                                <IoWifiOutline />
                                {i.workStatusName}
                              </span>
                            </Grid.Item>
                          </Grid>
                        </>
                      }
                    >
                      <Ellipsis
                        content={i.description}
                        rows={3}
                        direction="end"
                        expandText="แสดงเพิ่มเติม"
                        collapseText="แสดงน้อยลง"
                      />
                    </List.Item>
                  ))}
                </List>
              </>
            ) : (
              ""
            )}
          </InfiniteScroll>
        </PullToRefresh>
      </div>

      <Popup
        visible={visiblePopupDetail}
        bodyStyle={{
          backgroundColor: "#b5ceea",
          color: "#222222",
        }}
      >
        <Grid columns={4} gap={6}>
          <Grid.Item style={{ padding: 16, fontSize: "1.1em" }} span={3}>
            <span
              style={{
                backgroundColor: "#222222",
                padding: 3,
                color: "#ffffff",
                borderRadius: 5,
              }}
            >
              {informStore.informData.code}
            </span>{" "}
            {informStore.informData.workStatusName}
          </Grid.Item>
          <Grid.Item>
            <Button
              type="button"
              onClick={() => {
                setVisiblePopup(false);
              }}
              style={{
                right: 1,
                marginTop: 7,
                backgroundColor: "red",
                color: "#ffffff",
              }}
            >
              <CloseCircleFill /> ปิด
            </Button>
          </Grid.Item>
          <Grid.Item span={4}>
            <div style={{ padding: 2 }}>
              <Tabs
                defaultActiveKey="detail"
                activeKey={currentViewTab}
                onChange={(key: any) => {
                  setCurrentViewTab(key);
                  if (key === "calendar") {
                    informStore.setLoading(true);
                    setTimeout(() => {
                      setCurrentViewCountJob(0);
                      informStore
                        .getInformJobs(informStore.informData.id)
                        .then((rs: any) => {
                          console.log(rs.data.data);
                          if (rs.data.data) {
                            const items: any[] = rs.data.data;
                            items.map((item: any) => {
                              const isV: boolean = dayjs(
                                item.start_time
                              ).isValid();
                              if (isV) {
                                setCurrentViewCountJob((i) => i + 1);
                              }
                            });
                          }
                        })
                        .finally(() => {
                          console.log("count job", currentViewCountJob);
                        });
                    }, 0);
                  }
                }}
              >
                <Tabs.Tab title="รายละเอียด" key="detail">
                  <div
                    id="scrollbar-style-1"
                    style={{
                      height: "65vh",
                      overflowY: "scroll",
                      padding: "5px",
                      background: "#ffffff",
                      borderRadius: 10,
                    }}
                  >
                    {informStore.loadingInform ? (
                      <SpinLoading style={{ fontSize: "3em" }} />
                    ) : (
                      <InformDetail showBackButton={false} fileInputAccept="" />
                    )}
                  </div>
                </Tabs.Tab>

                {informStore.informData.work_status !== 6 ? (
                  <>
                    <Tabs.Tab title="ตารางนัดหมาย" key="calendar">
                      <div
                        style={{
                          height: "65vh",
                          overflowY: "scroll",
                          padding: "5px",
                          background: "#ffffff",
                          borderRadius: 10,
                        }}
                      >
                        Total : {currentViewCountJob}
                        {informStore.loadingJob ? <SpinLoading /> : ""}
                        {informStore.informJobs && currentViewCountJob > 0 ? (
                          <>
                            <List>
                              {informStore.informJobs.map(
                                (item: any, itemKey: number) => {
                                  if (item.start_time) {
                                    return (
                                      <>
                                        <List.Item
                                          key={itemKey}
                                          prefix={
                                            <>
                                              <div
                                                style={{ fontSize: "12px" }}
                                                key={itemKey}
                                              >
                                                #{item.id}
                                              </div>
                                              <GoCalendar size={50} />
                                            </>
                                          }
                                          title={
                                            item.group ? item.group.name : ""
                                          }
                                          description={
                                            <>
                                              <Grid
                                                columns={1}
                                                key={"grid" + itemKey}
                                              >
                                                <Grid.Item>
                                                  <UserCircleOutline />{" "}
                                                  {item.responsible_name}
                                                </Grid.Item>
                                                <Grid.Item>
                                                  <span
                                                    key={"jobstatus" + itemKey}
                                                    style={{
                                                      padding: 2,
                                                      marginLeft: 3,
                                                      float: "right",
                                                      marginRight: 5,
                                                      borderRadius: 5,
                                                      minWidth: 120,
                                                      textAlign: "center",
                                                      fontWeight: "normal",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    <IoWifiOutline />
                                                    {item.jobStatusName}
                                                  </span>
                                                </Grid.Item>
                                              </Grid>
                                            </>
                                          }
                                        >
                                          <div key={"dfsdfsdf" + item.id}>
                                            {" "}
                                            {item.created_at
                                              ? dayjs
                                                  .unix(item.created_at)
                                                  .format("DD/MM/YY HH:mm")
                                              : ""}
                                          </div>
                                          <strong
                                            style={{ color: "blueviolet" }}
                                          >
                                            {item.list}
                                          </strong>
                                          <p
                                            style={{ fontStyle: "normal" }}
                                            key={"tyrt" + item.id}
                                          >
                                            <small>{item.description}</small>
                                          </p>
                                        </List.Item>
                                      </>
                                    );
                                  }
                                }
                              )}
                            </List>
                          </>
                        ) : (
                          <Empty description="ไม่พบข้อมุล" />
                        )}
                      </div>
                    </Tabs.Tab>
                  </>
                ) : (
                  ""
                )}
              </Tabs>
            </div>
          </Grid.Item>
          <Grid.Item span={2}></Grid.Item>
        </Grid>
      </Popup>
    </>
  );
};
export default observer(InformsInfiniteScroll);
