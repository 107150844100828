import React, { useEffect } from "react";
import { FloatingBubble, Button } from "antd-mobile";
import { useNavigate } from "react-router";
import { GoPlus } from "react-icons/go";
import InformList from "../components/Informs";
import { observer } from "mobx-react-lite";
import Layout from "components/Layout";
import informStore from "store/inform.store";
import InformsInfiniteScroll from "components/InformsInfiniteScroll";

export const InformsPage = observer(() => {
  const navigate = useNavigate();
  // const { informStore } = useStore();
  const reloadData = () => {
    // let items = informStore.items;
    // informStore.loadInforms().then((data: any)=> {
    //   console.log(data);
    // })
  };
  const onClick = () => {
    informStore.setInformJobs([]);
    informStore.setInform({});
    navigate("/main/informs/create");
  };

  useEffect(() => {}, []);

  return (
    <>
      <Layout showNavbar={true}>
        <FloatingBubble
          style={{
            "--initial-position-bottom": "24px",
            "--initial-position-right": "24px",
            "--edge-distance": "24px",
          }}
          onClick={onClick}
        >
          <GoPlus fontSize={32} />
        </FloatingBubble>
        <InformsInfiniteScroll />
      </Layout>
    </>
  );
});
