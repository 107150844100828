
import axios from "axios";
import { http } from "./request"
class InformService {
  all(params: any) {
    return axios.get(`/informfix`, { params: params })
  }
  get(id: number) {
    return http.get(`/informfix/${id}`)
  }
  getJobsById(id: number) {
    return http.get(`/informfix/${id}/jobs`)
  }
  getImages(id: number) {
    return http.get(`/informfix/${id}/images`)
  }
  upload(id: number, data: any) {
    return http.post(`/informfix/${id}/upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
  }
  create(data: any) {
    return http.post(`/informfix`, data)
  }
  confirmCreate(id: number, data: any) {
    return http.post(`/informfix/${id}/confirm-create`, data)
  }
  deleateImage(informId: number, imgId: number) {
    return http.delete(`/informfix/${informId}/images/${imgId}`)
  }
  updateImageDescription(imgId: number, text: string) {
    return http.put(`/informfix/img-description/${imgId}`, {
      description: text
    })
  }
};
const informService = new InformService();
export default informService


