import React, { useEffect } from "react";
import { FloatingBubble, Button } from "antd-mobile";
import { useNavigate } from "react-router";
import { GoPlus } from "react-icons/go";
import InformList from "../components/Informs";
import { observer } from "mobx-react-lite";
import Layout from "components/Layout";
import { Profile } from "components/Profile";
import { LeftOutline } from "antd-mobile-icons";

export const ProfilePage = observer(() => {
  const navigate = useNavigate();
  // const { informStore } = useStore();
  const reloadData = () => {
    // let items = informStore.items;
    // informStore.loadInforms().then((data: any)=> {
    //   console.log(data);
    // })
  };
  const onClick = () => {
    navigate("/main/informs/create");
  };

  useEffect(() => {}, []);

  return (
    <>
      <Layout showNavbar={true}>
        <Button
          type="button"
          color="default"
          className="btn_topback"
          onClick={() => navigate("/main/informs")}
        >
          <LeftOutline /> กลับ
        </Button>
        <Profile />
      </Layout>
    </>
  );
});
