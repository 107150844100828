import React, { useContext, useEffect, useState } from "react";
import { Button, List, PullToRefresh } from "antd-mobile";
import {
  PayCircleOutline,
  UserCircleOutline,
  UserOutline,
} from "antd-mobile-icons";
import { IoHomeOutline, IoLogOutOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { GoRepoPush } from "react-icons/go";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores";

export const Profile = observer(() => {
  const navigate = useNavigate();
  const { authStore } = useStore();
  const [user, setUser] = useState(authStore.authUser);

  const logout = () => {
    authStore.logout().then(() => {
      setUser("");
      window.location.replace("/login");
    });
  };

  const loadProfile = () => {
    authStore.currentUser = {};
    authStore
      .getUser()
      .then(() => {
        console.log("user => ", authStore.currentUser);
        setUser(authStore.authUser);
      })
      .finally(() => {
        authStore.inProgress = false;
      });
  };

  useEffect(() => {
    loadProfile();
    if (!authStore.isLoggedIn()) {
      navigate("/login");
    } else {
    }
  }, []);
  return (
    <>
      <PullToRefresh onRefresh={async () => loadProfile()}>
        <List
          style={{
            maxWidth: 500,
            margin: "0 auto",
          }}
          mode="card"
        >
          <List.Item
            prefix={<UserCircleOutline />}
            title="Username"
            description=""
          >
            {user.username}
          </List.Item>
          <List.Item
            prefix={<UserCircleOutline />}
            title="ชื่อ - สกุล"
            description=""
          >
            {user.name}
          </List.Item>
          <List.Item
            prefix={<UserCircleOutline />}
            title="เบอร์มือถือ"
            description=""
          >
            {user.mobile}
          </List.Item>
          <List.Item prefix={<IoHomeOutline />} title="โครงการ">
            {user.home ? user.home.project.name : ""}
          </List.Item>
          <List.Item prefix={<PayCircleOutline />} title="บ้านเลขที่/ห้อง">
            {user.home && user.home.home ? user.home.home.home_no : ""}
            {user.home && user.home.home
              ? ` ( แปลง ${user.home.home.plan_no})`
              : ""}
          </List.Item>
          <List.Item prefix={<GoRepoPush />} title="ประเภท">
            {user.home && user.home.home.homeType
              ? user.home.home.homeType.name
              : ""}
          </List.Item>
          <List.Item prefix={<UserOutline />} title="เข้าสู่ระบบล่าสุด">
            {authStore.currentUser ? authStore.currentUser.lastLogin : ""}
          </List.Item>
          <List.Item prefix={<IoLogOutOutline />}>
            <Button type="button" color="danger" onClick={logout}>
              ออกจากระบบ
            </Button>
          </List.Item>
        </List>
      </PullToRefresh>
    </>
  );
});
