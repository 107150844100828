import React, { useEffect } from "react";
import { FloatingBubble, Button, Grid } from "antd-mobile";
import { useNavigate } from "react-router";
import { GoPlus } from "react-icons/go";
import InformList from "../components/Informs";
import { observer } from "mobx-react-lite";
import Layout from "components/Layout";
import Register from "components/Register";
import { LeftOutline } from "antd-mobile-icons";

export const LoginPage = observer(() => {
  const navigate = useNavigate();
  // const { informStore } = useStore();
  const reloadData = () => {
    // let items = informStore.items;
    // informStore.loadInforms().then((data: any)=> {
    //   console.log(data);
    // })
  };
  const onClick = () => {
    navigate("/main/informs/create");
  };

  useEffect(() => {}, []);

  return (
    <>
      <Layout showNavbar={false}>
        <h1 style={{ display: "block" }}>เข้าสู่ระบบ :: แจ้งซ่อมบ้าน</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "20vh",
          }}
        >
          <Register type="login" />
        </div>
      </Layout>
    </>
  );
});
