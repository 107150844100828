import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";
import store, { StoreContext } from "./stores";
import { ConfigProvider, ErrorBlock } from "antd-mobile";

import enUS from "antd-mobile/es/locales/en-US";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <BrowserRouter basename="/">
        <ConfigProvider locale={enUS}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </StoreContext.Provider>
  </React.StrictMode>
);
