import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import "./css/main.scss";
import { motion } from "framer-motion";

import { TabBar, Badge } from "antd-mobile";
import {
  AppOutline,
  MessageOutline,
  MessageFill,
  UnorderedListOutline,
  UserOutline,
} from "antd-mobile-icons";

import Message from "./components/Message";
import News from "./components/News";
import InformForm from "./components/InformForm";
import InformDetail from "./components/InformDetail";
// import TodoDetails from "./components/TodoDetails";

import About from "./components/About";

import Error404 from "./components/Error404";
import { InformsPage } from "pages/InformsPage";
import { Profile } from "components/Profile";
import { observer } from "mobx-react-lite";
import Informs from "components/Informs";
import { ProfilePage } from "pages/ProfilePage";
import { LoginPage } from "pages/LoginPage";
import { RegisterPage } from "pages/RegisterPage";
import Layout from "components/Layout";
import { InformDetailPage } from "pages/InformDetailPage";


function App() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="main">
          <Route path=""  element={<InformsPage />}/>
          <Route path={`informs`} element={<InformsPage />} />
          <Route path={`informs/create`} element={<Layout showNavbar={true}><InformForm /></Layout>} />
          <Route path={`informs/:id`} element={<InformDetailPage />} />
          <Route path={`news`} element={<News />} />
          <Route path={`message`} element={<Message />} />
          <Route path={`profile`} element={<ProfilePage />} />
        </Route>
        <Route path="register" element={<RegisterPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="about" element={<About />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}

export default observer(App);
