import axios from 'axios';
import { observable, action, reaction, computed, makeAutoObservable } from 'mobx';

import authService from 'services/auth.service';
import { http } from 'services/request';


class AuthStore {
  @observable inProgress = false;
  @observable errors = undefined;
  @observable token = localStorage.getItem('token');
  @observable currentUser: any = ""


  @observable values: any = {
    username: '',
    email: '',
    password: '',
  };

  // inProgress: boolean = false;
  // errors: any = undefined;
  // token: any = "";
  // currentUser: any = {};
  // authToken: any = "";

  // values: any = {
  //   username: '',
  //   email: '',
  //   password: '',
  // }

  constructor() {
    reaction(
      () => this.token,
      token => {
        console.log('react', token);
        if (token) {
          localStorage.setItem('token', token);
        } else {
          localStorage.removeItem('token');
        }
      }
    );
    // makeObservable(this, {
    //   token: observable,
    //   currentUser: observable,
    //   values: observable
    // })
    makeAutoObservable(this)
  }

  @computed
  get authToken() {
    return this.token;
  }
  @computed
  get authUser() {
    return this.currentUser;
  }

  @action isLoggedIn() {
    return this.token ? true : false;
  }

  @action setUsername(username: string) {
    this.values.username = username;
  }

  @action setCurrentUser(data: any) {
    this.currentUser = data;
  }

  // @action setEmail(email: string) {
  //   this.values.email = email;
  // }

  // @action setPassword(password: string) {
  //   this.values.password = password;
  // }
  @action setToken(token: any) {
    this.token = token;
    localStorage.setItem('token', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  // @action reset() {
  //   this.values.username = '';
  //   this.values.email = '';
  //   this.values.password = '';
  // }

  @action login(form: any) {
    this.inProgress = true;
    this.errors = undefined;
    return authService.login(form)
      .then((rs: any) => {
        console.log(rs.data);
        if (rs.data) {
          this.setToken(rs.data.data.token)
          this.getUser();
        }
        return rs;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action register(form: any) {
    this.inProgress = true;
    this.errors = undefined;
    return authService.register(
      form.username,
      form.name,
      form.project_id,
      form.home_id,
    )
      .then((user: any) => {
        this.setToken(user.data.token)
        this.getUser()
        return user;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action getUser() {
    this.inProgress = true;
    return authService.me().then((rs: any) => {
      // console.log('=>', rs.data.data);
      this.inProgress = false;
      this.setCurrentUser(rs.data.data)
    });

  }

  @action logout() {
    this.setToken('');
    this.currentUser = {};
    localStorage.removeItem('token')
    return Promise.resolve();
  }

  @action checkForExpiredToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const currentTime = Date.now() / 1000;
      console.log('check token', currentTime);
      // if (user.exp >= currentTime) {
      //   this.setUser(token);
      // } else {
      //   this.logout();
      // }
    }
  };
}
const authStore = new AuthStore();

authStore.checkForExpiredToken();

export default authStore;






