import React, { useEffect } from "react";
import { FloatingBubble, Button, Grid, Card } from "antd-mobile";
import { useNavigate } from "react-router";
import { GoPlus } from "react-icons/go";
import InformList from "../components/Informs";
import { observer } from "mobx-react-lite";
import Layout from "components/Layout";
import Register from "components/Register";
import { LeftOutline } from "antd-mobile-icons";

export const RegisterPage = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <>
      <Layout showNavbar={false}>
        <h1 style={{ display: "block" }}>ลงทะเบียนใหม่ :: แจ้งซ่อมบ้าน</h1>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "20vh",
          }}
        >
          <Register type="register" />
        </div>
      </Layout>
    </>
  );
});
