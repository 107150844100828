import React from 'react'


function About() {
  return (
      <>
      About
      </>
  )
}

export default About
