import {
  Image,
  ImageUploader,
  List,
  Space,
  ImageViewer,
  Input,
  Button,
  TextArea,
  Toast,
  Dialog,
  SpinLoading,
  Checkbox,
  Form,
  Grid,
  Empty,
  FloatingBubble,
} from "antd-mobile";

import { observer } from "mobx-react-lite";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useStore } from "stores";

import {
  ClockCircleFill,
  PhoneFill,
  UserCircleOutline,
} from "antd-mobile-icons";

import dayjs, { Dayjs } from "dayjs";
import informService from "services/inform.service";
import {
  IoCameraSharp,
  IoImageOutline,
  IoSave,
  IoSaveOutline,
  IoSaveSharp,
  IoTrashBinSharp,
} from "react-icons/io5";
import { values } from "mobx";
import { GoUpload } from "react-icons/go";
import { time } from "console";
interface informDetailProps {
  showBackButton?: boolean;
  fileInputAccept?: string;
}
function InformDetail(props: informDetailProps) {
  const { informStore } = useStore();
  const [images, setImages] = useState([]);
  const [viewImage, setViewImage] = useState();
  const [visible, setVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const fileInput = useRef<any>();
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [loadingSave, setLoadingSave] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {}, [informStore.informData]);

  const fileInputAccept = props.fileInputAccept
    ? props.fileInputAccept
    : "image/*";

  const showUploadDialog = (e: any) => {
    fileInput.current.click();
  };

  const upload = (files: any) => {
    setLoadingUpload(true);
    Toast.show("กำลังอัพโหลด.....");
    const countFile: number = files.length;
    for (let i = 0; i < countFile; i++) {
      const file = files[i];
      const body: any = new FormData();
      body.append("file", file);
      informStore
        .upload(informStore.informData.id, body)
        .then((rs: any) => {
          if (rs.data.filename !== undefined) {
          }
        })
        .catch((err: any) => {
          setLoadingUpload(false);
          Toast.show(err.response.message);
        })
        .finally(() => {
          setLoadingUpload(false);
          if (i + 1 === countFile) {
            Toast.show("อัพโหลดไฟล์เรียบร้อยแล้ว");
            informService
              .getImages(informStore.informData.id)
              .then((rs: any) => {
                if (rs.data.data) {
                  let inform = informStore.informData;
                  inform.images = rs.data.data;
                  informStore.setInform(inform);
                }
              });
          }
        });
    }
  };

  const handleFileChange = (event: any) => {
    Toast.clear();
    upload(event.target.files);
  };

  let defaltImages: any = [];
  let descriptionImages: any = [];
  let countActiveJob: number = 0;

  if (informStore.informData && informStore.informData.images) {
    informStore.informData.images.map((item: any) => {
      defaltImages.push(item.url);
      descriptionImages.push({
        id: item.id,
        description: item.description,
        url: item.url,
        thumbnail: item.thumbnail,
      });
    });
  }

  const onChangeDescripttionImage = (e: any, item: any) => {
    const text: string = e.target.value;
    const index: number = informStore.informData.images.indexOf(item);
    if (text !== item.description) {
      informService.updateImageDescription(item.id, text).then((rs: any) => {
        // Toast.show(rs.data.message);
        let inform = informStore.informData;
        inform.images[index].description = text;
        informStore.setInform(inform);
      });
    }
  };
  const deleteImage = (item: any) => {
    Dialog.confirm({
      header: "ต้องการลบรูปภาพนี้ใช่หรือไม่ ?",
      confirmText: "ใช่",
      cancelText: "ยกเลิก",
      onConfirm: () => {
        informService
          .deleateImage(informStore.informData.id, item.id)
          .then((rs) => {
            Toast.show("ลบรายการเรียบร้อย");
            informStore.getInformDetail(informStore.informData.id);
          });
      },
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      contact_phone: informStore.informData.contact_phone
        ? informStore.informData.contact_phone
        : "",
      description: informStore.informData.description
        ? informStore.informData.description
        : "",
      informer: informStore.informData.informer
        ? informStore.informData.informer
        : "",
    });
  }, [informStore.informData]);

  const confirmInform = (values: any) => {
    if (values) {
      // if (
      //   informStore.informData.images &&
      //   informStore.informData.images.length === 0
      // ) {

      //   Dialog.alert({
      //     content: "โปรดอัพโหลดรูปภาพอย่างน้อย 1 รูป ก่อนส่งใบแจ้งซ่อม",
      //     confirmText: "ปิด",
      //   });
      //   return false;
      // } else {

      // }
      Dialog.confirm({
        title: `ยันยันส่งใบแจ้งซ่อม  ? `,
        content: `${informStore.informData.code} \r โปรดตรวจทานข้อมูลก่อนส่ง   เมื่อส่งใบแจ้งซ่อมแล้ว คุณจะไม่สามารถแก้ไขข้อมูลได้อีก`,
        confirmText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onConfirm: () => {
          informService
            .confirmCreate(informStore.informData.id, values)
            .then((rs) => {
              if (rs.data) {
                Dialog.alert({
                  header: `${rs.data.data.code}`,
                  content: rs.data.message,
                  confirmText: "ปิด",
                });
                informStore.getInformDetail(informStore.informData.id);
              }
            });
        },
      });
    }
  };

  const canEdit = () => Number(informStore.informData.work_status) === 6;

  const renderFooterImageViewer = (image: string, index: number) => {
    return (
      <div key={"dffgdsf" + index}>
        <div
          key={"img_description" + index}
          style={{
            padding: 5,
            backgroundColor: "rgba(1,1,1,.5)",
            color: "#ffffff",
          }}
          onClick={() => {
            console.log("Loading...");
          }}
        >
          {descriptionImages[index] !== undefined
            ? descriptionImages[index].description
            : ""}
        </div>
      </div>
    );
  };

  return (
    <>
      {informStore.informData ? (
        <>
          <Form
            layout="horizontal"
            // mode="card"
            form={form}
            onFinish={confirmInform}
            footer={
              <>
                {Number(informStore.informData.work_status) === 6 ? (
                  <>
                    <Button block type="submit" color="primary" size="large">
                      <IoSave /> ยืนยัน/ส่งใบแจ้งซ่อม
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </>
            }
          >
            <Form.Item label="เลขที่แจ้ง">
              {informStore.informData.code}
            </Form.Item>
            <Form.Item label="สถานะ">
              {informStore.informData.workStatusName}
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="รายละเอียด"
              name="description"
              rules={[
                { required: true, message: "รายละเอียดความเสีย/ข้อความ" },
              ]}
            >
              {canEdit() ? (
                <TextArea placeholder="รายละเอียดความเสีย/ข้อความ" />
              ) : (
                informStore.informData.description
              )}
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  marginTop: 10,
                  // backgroundColor: "#222222",

                  marginBottom: 20,
                }}
              >
                <input
                  type="file"
                  multiple={true}
                  accept={fileInputAccept}
                  ref={fileInput}
                  onChange={(e) => handleFileChange(e)}
                  style={{ display: "none" }}
                />

                {informStore.informData.work_status === 6 &&
                informStore.informData.work_status !== 3 ? (
                  <>
                    <List header="รูปภาพ" mode="card">
                      {informStore.informData.images.map(
                        (item: any, key: number) => (
                          <List.Item
                            style={{
                              padding: 3,
                            }}
                            key={key}
                            description={
                              <div style={{ marginTop: 5 }}>
                                <span
                                  onClick={() => deleteImage(item)}
                                  style={{
                                    marginRight: 20,
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                >
                                  <IoTrashBinSharp /> ลบ
                                </span>
                                <ClockCircleFill />{" "}
                                {dayjs
                                  .unix(item.created_at)
                                  .format("DD/MM/YYYY HH:mm")}
                              </div>
                            }
                            prefix={
                              <Image
                                onClick={() => {
                                  setViewImage(item.url);
                                  setVisible(true);
                                }}
                                key={key}
                                src={item.thumbnail}
                                width={130}
                                fit="contain"
                                style={{
                                  margin: 0,
                                  padding: 1,
                                }}
                              />
                            }
                          >
                            <TextArea
                              defaultValue={
                                item.description ? item.description : ""
                              }
                              onBlur={(event: any) =>
                                onChangeDescripttionImage(event, item)
                              }
                              readOnly={!canEdit()}
                              placeholder="คำอธิบายรูปภาพ"
                              style={{
                                backgroundColor: "#f3f3f3",
                                padding: 3,
                                "--font-size": "12px",
                              }}
                            />
                          </List.Item>
                        )
                      )}
                    </List>
                    <Button
                      color="default"
                      type="button"
                      size="large"
                      shape="rounded"
                      onClick={showUploadDialog}
                      style={{
                        backgroundColor: "black",
                        color: "#ffffff",
                        // borderRadius: 0,
                        width: 200,
                      }}
                      loading={loadingUpload}
                    >
                      <GoUpload />
                      <div style={{ display: "inline" }}> อัพโหลดรูปภาพ</div>
                    </Button>
                    {images ? (
                      <ImageViewer
                        image={viewImage}
                        visible={visible}
                        renderFooter={(image: any) => (
                          <div
                            style={{
                              padding: 5,
                              backgroundColor: "#222222",
                              color: "#ffffff",
                            }}
                          >
                            {image.description}
                          </div>
                        )}
                        onClose={() => {
                          setVisible(false);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    {informStore.informData.images &&
                    informStore.informData.images.length > 0 ? (
                      <div>
                        <List>
                          {informStore.informData.images.map(
                            (item: any, key: number) => (
                              <List.Item
                                key={key}
                                style={{
                                  margin: 2,
                                  // backgroundColor: "#f3f3f3",
                                }}
                                prefix={
                                  <Image
                                    alt={item.description}
                                    onClick={() => {
                                      setImageIndex(key);
                                      setVisible(true);
                                    }}
                                    src={item.thumbnail}
                                    fit="contain"
                                    width={120}
                                  />
                                }
                                description={
                                  <>
                                    <ClockCircleFill />{" "}
                                    {dayjs
                                      .unix(item.created_at)
                                      .format("DD/MM/YYYY HH:mm")}
                                  </>
                                }
                              >
                                {item.description}
                              </List.Item>
                            )
                          )}
                        </List>
                      </div>
                    ) : (
                      <Empty description="ไม่มีรูปภาพ" />
                    )}
                    <Button
                      color="default"
                      type="button"
                      size="large"
                      shape="rounded"
                      onClick={showUploadDialog}
                      style={{
                        backgroundColor: "black",
                        color: "#ffffff",
                        // borderRadius: 0,
                        width: 200,
                      }}
                      loading={loadingUpload}
                    >
                      <GoUpload />
                      <div style={{ display: "inline" }}> อัพโหลดรูปภาพ</div>
                    </Button>
                    {images ? (
                      <ImageViewer.Multi
                        images={defaltImages}
                        defaultIndex={imageIndex}
                        visible={visible}
                        onIndexChange={(index) => {
                          setImageIndex(index);
                        }}
                        onClose={() => {
                          setVisible(false);
                        }}
                        renderFooter={renderFooterImageViewer}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </Form.Item>
            <Form.Item
              layout="vertical"
              name="informer"
              rules={[
                {
                  required: true,
                  message: "ผู้แจ้ง",
                },
              ]}
              label={
                <>
                  <UserCircleOutline /> ผู้แจ้ง
                </>
              }
            >
              <Input readOnly={!canEdit()} type="text" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              name="contact_phone"
              rules={[
                {
                  required: true,
                  message: "โปรดระบุเบอร์ติดต่อกลับด้วย",
                },
              ]}
              label={
                <>
                  <PhoneFill /> เบอร์ติดต่อ
                </>
              }
            >
              <Input readOnly={!canEdit()} type="tel" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label={
                <>
                  <ClockCircleFill /> เวลา
                </>
              }
            >
              {dayjs
                .unix(informStore.informData.created_at)
                .format("DD/MM/YY HH:mm")}
            </Form.Item>
          </Form>
        </>
      ) : (
        ""
      )}
      {informStore.informData && informStore.informData.work_status === 6 ? (
        <FloatingBubble
          style={{
            "--initial-position-bottom": "50px",
            "--initial-position-right": "24px",
            "--edge-distance": "24px",
            "--background": "black",
          }}
          onClick={showUploadDialog}
        >
          <IoImageOutline fontSize={32} />
        </FloatingBubble>
      ) : (
        ""
      )}
    </>
  );
}
export default observer(InformDetail);
