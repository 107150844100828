import React from 'react'


function News() {
  return (
      <>
      News
      </>
  )
}

export default News
