
import {http} from "./request"

class projectService {
  all() {
    return http.get(`/projects/all`)
  }
  get(id: string) { http.get(`/projects/${id}`) }

  getHomesByProjectId(id: string) {
    return http.get(`/projects/${id}/homes`)
  }
};

export default new projectService();


