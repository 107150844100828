import { action, toJS } from "mobx";
import { useStore } from "../stores";
import {
  Form,
  Input,
  Button,
  TextArea,
  Steps,
  Card,
  Toast,
  ImageUploader,
  Dialog,
} from "antd-mobile";
import { useNavigate } from "react-router";

import { useEffect, useRef, useState } from "react";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

import { ImageUploadItem } from "antd-mobile/es/components/image-uploader";
import { sleep } from "antd-mobile/es/utils/sleep";
import { IoCreateSharp, IoSaveOutline } from "react-icons/io5";
import informStore from "store/inform.store";
import authStore from "store/auth.store";
import { LeftOutline } from "antd-mobile-icons";

const InformForm = (props: any) => {
  // const { informStore } = useStore();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [maxCount, setMaxCount] = useState(6);
  const [formData, setFormData] = useState<any>();
  const [inform, setInform] = useState<any>();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<ImageUploadItem[]>([
    {
      url: "https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=60",
    },
  ]);

  const handleSubmit = action((values: any) => {
    const _values: any = { ...formData, ...values };
    setFormData(_values);
    if (currentStep === 0 && !inform) {
      Dialog.confirm({
        content: "ยืนยันสร้างใบแจ้งซ่อมใหม่ ?",
        confirmText: "ตกลง",
        cancelText: "ยกเลิก",
        onConfirm: async () => {
          Toast.show({
            icon: "success",
            content: "สร้างสำเร็จแล้ว",
            position: "bottom",
          });
          informStore
            .create(_values)
            .then((rs: any) => {
              if (rs.data.success) {
                Toast.show(rs.data.message);
                setCurrentStep(currentStep + 1);
                Dialog.alert({
                  header: "Info",
                  content: (
                    <>
                      <div>อย่าลืมอัพโหลดรูปภาพ อย่างน้อย 1 รูปภาพ</div>
                    </>
                  ),
                  confirmText: "ตกลง",
                  afterClose: () => {
                    navigate("/main/informs/" + informStore.informData.id);
                  },
                });
              }
            })
            .catch((err) => {
              Toast.show(err.response.statusText);
            });
          // setInform({
          //   id: 1,
          //   code: "wcu001",
          //   description: "น้ำรั่ว",
          // });
        },
      });
    } else {
      setCurrentStep(currentStep + 1);
      if (currentStep === 2) {
        console.log("values", formData);
        Toast.show({
          content: "อัพเดทข้อมุลเรียบร้อย",
          afterClose: () => {
            navigate("/main/informs");
          },
        });
      }
    }
  });

  useEffect(() => {
    authStore.getUser().then((rs: any) => {
      const user = authStore.authUser;
      form.setFieldValue("informer", user.name);
      form.setFieldValue("contact_phone", user.mobile);
      if (user.home) {
        form.setFieldValue("home_id", user.home.home_id);
        form.setFieldValue("project_id", user.home.project_id);
      }
    });
  }, [currentStep]);

  const nextStep = () => {
    if (currentStep >= 0 && currentStep < 2) {
      if (currentStep === 0 && !inform) {
        form.submit();
        return;
      } else {
        form.submit();
        // setCurrentStep(currentStep + 1);
      }
    }
  };
  const prevStep = () => {
    if (currentStep > 0 && currentStep <= 2) {
      setCurrentStep(currentStep - 1);
    }
  };

  async function mockUpload(file: File) {
    await sleep(3000);
    return {
      url: URL.createObjectURL(file),
    };
  }

  async function mockUploadFail() {
    await sleep(3000);
    throw new Error("Fail to upload");
  }
  return (
    <div>
      <Button
        type="button"
        color="default"
        onClick={() => navigate("/main/informs")}
        className="btn_topback"
      >
        <LeftOutline /> กลับ
      </Button>
      <div>
        <Card
          title={inform ? "ใบแจ้งซ่อม#" + inform.code : "สร้างใบแจ้งซ่อมใหม่"}
          style={{
            maxWidth: 500,
            margin: "0 auto",
          }}
        >
          <Steps current={currentStep}>
            <Steps.Step
              title="ข้อมูลผู้แจ้ง"
              description="รายละเอียดความเสียงหาย"
            />
            <Steps.Step title="รูปภาพ" description="อย่างน้อย 1 รูปภาพ" />
          </Steps>

          <Form
            form={form}
            style={{
              border: "solid 1px #f5f5f5",
            }}
            onFinish={handleSubmit}
          >
            <div
              style={{
                minHeight: 300,
                padding: 10,
              }}
            >
              {currentStep === 0 ? (
                <>
                  <Form.Item
                    name="project_id"
                    label="โครงการ"
                    rules={[{ required: true, message: "โครงการ" }]}
                    noStyle
                    style={{ display: "none" }}
                  >
                    <Input type="hidden" placeholder="โปรดระบุชื่อผู้แจ้ง" />
                  </Form.Item>
                  <Form.Item
                    name="home_id"
                    label="บ้าน"
                    rules={[{ required: true, message: "บ้าน" }]}
                    style={{ display: "none" }}
                    noStyle
                  >
                    <Input type="hidden" placeholder="บ้าน" />
                  </Form.Item>
                  <Form.Item
                    name="informer"
                    label="ชื่อผู้แจ้ง"
                    rules={[{ required: true, message: "โปรดระบุชื่อผู้แจ้ง" }]}
                  >
                    <Input placeholder="โปรดระบุชื่อผู้แจ้ง" type="text" />
                  </Form.Item>
                  <Form.Item
                    name="contact_phone"
                    label="เบอร์ติดต่อกลับ"
                    rules={[
                      { required: true, message: "โปรดระบุเบอร์ติดต่อกลับ" },
                    ]}
                  >
                    <Input placeholder="เบอร์ติดต่อกลับ" type="tel" />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="ข้อความ"
                    rules={[
                      {
                        required: true,
                        message: "โปรดระบุรายละเอียด",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="ระบุรายละเอียด"
                      maxLength={100}
                      rows={3}
                      showCount
                      value="รายละเอียดหรือข้อความ"
                    />
                  </Form.Item>
                </>
              ) : (
                ""
              )}

              {currentStep === 1 ? (
                <>
                  <Form.Item
                    name="comment"
                    label="ความคิดเห็น"
                    rules={[
                      {
                        required: true,
                        message: "ความคิดเห็น",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="ความคิดเห็น"
                      maxLength={100}
                      rows={3}
                      showCount
                      value="ความคิดเห็น"
                    />
                  </Form.Item>
                </>
              ) : (
                ""
              )}
              {currentStep === 2 ? (
                <>
                  <ImageUploader
                    value={fileList}
                    onChange={setFileList}
                    upload={mockUpload}
                    multiple
                    maxCount={3}
                    showUpload={fileList.length < maxCount}
                    onCountExceed={(exceed) => {
                      Toast.show(
                        `最多选择 ${maxCount} 张图片，你多选了 ${exceed} 张`
                      );
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>

            <Form.Item>
              <Button
                type="button"
                color="default"
                onClick={() => navigate("/main/informs")}
              >
                <LeftOutline /> กลับ
              </Button>

              {currentStep > 0 ? (
                <Button
                  type="button"
                  color="default"
                  style={{ marginLeft: 5 }}
                  disabled={currentStep === 0}
                  onClick={() => prevStep()}
                >
                  <GoArrowLeft /> ก่อนหน้า
                </Button>
              ) : (
                ""
              )}
              {currentStep < 2 ? (
                <Button
                  type="button"
                  color="primary"
                  style={{ marginLeft: 5 }}
                  disabled={currentStep === 2}
                  onClick={() => nextStep()}
                >
                  ถัดไป <GoArrowRight />
                </Button>
              ) : (
                ""
              )}
              {currentStep === 2 ? (
                <Button
                  type="submit"
                  size="small"
                  color="primary"
                  style={{ marginLeft: 5 }}
                >
                  <IoSaveOutline /> บันทึก
                </Button>
              ) : (
                ""
              )}
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default InformForm;
