import { createContext, useContext } from "react";

import authStore  from "store/auth.store";
import informStore from "store/inform.store";
// interface IStoreContext {
//     authStore: authStore;
//   }

const store = {
    authStore: authStore,
    informStore:informStore,
};

export const StoreContext = createContext(store);
export const useStore = () => {
    return useContext<typeof store>(StoreContext);
}

export default store;