
import {http} from "./request"

class authService {
  login(form: any) {
    return http.post("/auth/login", {
      username: form.username,
      password: form.password,
    })
  }


  check(mobile: string) {
    return http.post("/auth/check", {
      username: mobile,
    });
  }

  
  register(mobile: string, name: string, project_id: number, home_id: number) {
    return http.post("/auth/register", {
      username: mobile,
      name: name,
      project_id: project_id,
      home_id: home_id,
    });
  }

  me() {
    return http.get("/auth/me")
  }
}

export default new authService();
