import { Grid, List, SearchBar } from "antd-mobile";
import { useEffect } from "react";
import { useState } from "react";
type ProjectSelectInputProps = {
  items: any[];
  onChange: Function;
  selected: any;
};
const ProjectSelectInput = (props: ProjectSelectInputProps) => {
  const [projects, setProjects] = useState(props.items);
  const [projectId, setProjectId] = useState();
  const [search, setSearch] = useState();

  const onSelecect = (item: any) => {
    setProjectId(item.id);
    if (props.onChange) {
      props.onChange(item);
    }
  };

  const filterByName = (filteredData: any) => {
    // Avoid filter for null value
    if (!search) {
      return filteredData;
    }
    const filteredProjects = filteredData.filter((project: any) =>
      project.name.includes(search)
    );

    return filteredProjects;
  };

  const list = filterByName(projects);

  useEffect(() => {
    if (props.selected) {
      setProjectId(props.selected);
    }
  }, [props.selected]);

  useEffect(() => {
    filterByName(projects);
  }, []);

  return (
    <>
      <Grid
        columns={1}
        gap={8}
        // style={{
        //   height: 350,
        // }}
      >
        <Grid.Item>
          <SearchBar
            onChange={(v: any) => {
              setSearch(v);
            }}
            onSearch={(v: any) => {
              setSearch(v);
            }}
          ></SearchBar>
        </Grid.Item>
        <Grid.Item
          style={{
            overflowY: "scroll",
            marginTop: 2,
          }}
        >
          <List header="โครงการ" key="dd-projectd">
            {list
              ? list.map((item: any, key: number) => {
                  return (
                    <List.Item
                      key={key}
                      onClick={() => onSelecect(item)}
                      style={{
                        fontSize: "0.8rem",
                      }}
                    >
                      {item.name}
                    </List.Item>
                  );
                })
              : ""}
          </List>
        </Grid.Item>
      </Grid>
    </>
  );
};

export default ProjectSelectInput;
