import React, { useEffect, useRef, useState } from "react";
import {
  FloatingBubble,
  Button,
  FloatingPanel,
  PullToRefresh,
  Dialog,
} from "antd-mobile";
import { useNavigate } from "react-router";
import { observer } from "mobx-react-lite";
import Layout from "components/Layout";
import InformDetail from "components/InformDetail";
import { useStore } from "stores";
import { useParams } from "react-router";
import ImageUploader, {
  ImageUploadItem,
} from "antd-mobile/es/components/image-uploader";
import { FloatingPanelRef } from "antd-mobile/es/components/floating-panel";
import { sleep } from "antd-mobile/es/utils/sleep";
import { BankcardOutline, LeftOutline } from "antd-mobile-icons";
import { IoArrowBack, IoSaveSharp } from "react-icons/io5";
import informService from "services/inform.service";

export const InformDetailPage = observer(() => {
  const navigate = useNavigate();
  const params = useParams();

  const { informStore, authStore } = useStore();
  const [images, setImages] = useState([]);
  const [visible, setVisible] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [fileList, setFileList] = useState<ImageUploadItem[]>([
    {
      url: "",
    },
  ]);

  const reloadData = () => {
    informStore.getInformDetail(params.id).then((data: any) => {});
    setTimeout(() => {
      if (informStore.informData && informStore.informData.work_status) {
        if (Number(informStore.informData.work_status) === 6) {
          Dialog.alert({
            header: "โปรดทราบ",
            content:
              "กรุณา ตรวจข้อมูล/แก้ไข พร้อมกับอัพโหลดรูปภาพ  และ กดยืนยันส่งใบแจ้งซ่อมเลขที่ : " +
              informStore.informData.code,
            confirmText: "ปิด",
            onConfirm:() => {
              Dialog.clear();
            }
          });
        }
      }
    }, 3000);
  };

  const onClick = () => {
    navigate("/main/informs/create");
  };

  const inform = informStore.informData;

  useEffect(() => {
    informStore.setInform({});
    reloadData();
  }, []);

  const ref = useRef<FloatingPanelRef>(null);

  // const confirmInform = () => {
  //   Dialog.confirm({
  //     title: `ยันยันส่งใบแจ้งซ่อม  ? `,
  //     content: `${inform.code} \r โปรดตรวจทานข้อมูลก่อนส่ง   เมื่อส่งใบแจ้งซ่อมแล้ว คุณจะไม่สามารถแก้ไขข้อมูลได้อีก`,
  //     confirmText: "ยืนยัน",
  //     cancelText: "ยกเลิก",
  //     onConfirm: () => {
  //       informService.confirmCreate(informStore.informData.id, {}).then((rs) => {
  //         console.log("confirm", rs);
  //         if (rs.data) {
  //           Dialog.alert({
  //             header: `${rs.data.data.code}`,
  //             content: rs.data.message,
  //             confirmText: "ปิด",
  //           });
  //           informStore.getInformDetail(informStore.informData.id);
  //         }
  //       });
  //     },
  //   });
  // };

  return (
    <>
      <Layout showNavbar={true}>
        {/* <FloatingPanel
          anchors={anchors}
          style={{
            padding:5
          }}
        >
          {" "}
          <Button>ยืนยันส่งใบแจ้งซ่อม</Button>
        </FloatingPanel> */}
        {/* <ImageUploader
            value={[]}
            maxCount={6}
            upload={mockUpload}
            preview={false}
            multiple={true}
          /> */}

        <PullToRefresh
          onRefresh={async () => {
            await sleep(1000);
            reloadData();
          }}
        >
          <Button
            type="button"
            color="default"
            onClick={() => navigate("/main/informs")}
            className="btn_topback"
          >
            <LeftOutline /> กลับ
          </Button>
          <InformDetail />
        </PullToRefresh>
      </Layout>
    </>
  );
});
