import React, { useState } from "react";
import { TabBar, Badge } from "antd-mobile";
import {
  AppOutline,
  UnorderedListOutline,
  MessageOutline,
  MessageFill,
  UserOutline,
} from "antd-mobile-icons";
import { useNavigate } from "react-router";
import { useStore } from "stores";
import { observer } from "mobx-react-lite";
const Navbar = () => {
  const navigate = useNavigate();
  const { informStore } = useStore();

  const tabs = [
    {
      key: "informs",
      title: "ประวัติแจ้งซ่อม",
      icon: <AppOutline />,
      badge:
        informStore.totalCountItems > 0 ? informStore.totalCountItems : null,
      path: "/main/informs",
    },
    // {
    //   key: "news",
    //   title: "ข่าวสาร",
    //   icon: <UnorderedListOutline />,
    //   badge: "5",
    //   path: "/main/news",
    // },
    // {
    //   key: "message",
    //   title: "ข้อความ",
    //   icon: (active: boolean) =>
    //     active ? <MessageFill /> : <MessageOutline />,
    //   badge: "99+",
    //   path: "/main/message",
    // },
    {
      key: "profile",
      title: "โปรไฟล์",
      icon: <UserOutline />,
      path: "/main/profile",
    },
  ];

  const onChangeTab = (key: string) => {
    navigate(`${key}`);
  };
  return (
    <>
      <TabBar onChange={onChangeTab} activeKey="" className="Navbar">
        {tabs.map((item) => (
          <TabBar.Item
            key={item.path}
            icon={item.icon}
            title={item.title}
            badge={item.badge}
            style={{
              marginTop: 5
            }}
          />
        ))}
      </TabBar>
    </>
  );
};

export default observer(Navbar);
