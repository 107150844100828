import React from 'react'


function Message() {
  return (
      <>
      Message
      </>
  )
}

export default Message
