import React from "react";
import { TabBar } from "antd-mobile";
import Navbar from "./Navbar";
import { observer } from "mobx-react-lite";
type Props = {
  showNavbar: boolean;
  children?: JSX.Element | JSX.Element[];
};
const Layout = ({ showNavbar, children }: Props) => (
  <>
    {showNavbar ? <Navbar /> : ""}
  <div
      className="Content"
      style={{
        paddingBottom: 50,
      }}
    >
      {children }
    </div>
  </>
);
export default observer(Layout);
